import $ from 'jquery';
import AOS from 'aos';

$(document).ready(function() {

    /* MATCH HEIGHT EQUAL HEIGHT

    $('.matchHeight').matchHeight();
     */

    /* SWITCHER
    $('.toggle-btn').click(function() {
        $(this).parent().toggleClass('active').siblings().removeClass('active');
        //$(this).parent().find('.single_switcher__active').toggleClass('active').siblings().removeClass('active');
    });
     */

    /* AOS ANIMATION FUNCTION

    $(function() {
        AOS.init();
    });
     */

  $('#toggle-variations-btn').change(function() {
      $('.variations').toggleClass('active');
  
      // Si les variations sont cachées, sélectionnez les options par défaut.
      if (!$('.variations').hasClass('active')) {
          // Réinitialiser la première option par défaut
          $('select[name=attribute_pa_exemplaires]').append('<option value="0">Option par défaut 1</option>');
          $('select[name=attribute_pa_exemplaires]').val('0').trigger('change');
  
          // Réinitialiser la deuxième option par défaut
          $('select[name=attribute_pa_type-de-papier]').append('<option value="pas-dimpression">Option par défaut 2</option>');
          $('select[name=attribute_pa_type-de-papier]').val('pas-dimpression').trigger('change');
          
          // Réinitialiser la deuxième option par défaut
            $('select[name=attribute_pa_modeles]').append('<option value="pas-dimpression">Option par défaut 2</option>');
            $('select[name=attribute_pa_modeles]').val('pas-dimpression').trigger('change');
      } else {
          // Lorsque les variations sont affichées, retirez les options par défaut
          $('select[name=attribute_pa_exemplaires]').find('option[value=0]').remove();
          $('select[name=attribute_pa_type-de-papier]').find('option[value=pas-dimpression]').remove();
          $('select[name=attribute_pa_modeles]').find('option[value=pas-dimpression]').remove();
      }
  });

      
});